import React, { Suspense } from 'react';
import Preloader from './components/UIElements/Preloader/Preloader';

const Front = React.lazy(() => {
  return import('./pages/Front/Front');
});


const App = () => {
  return <Suspense fallback={<Preloader />}><Front /></Suspense>;
}
export default App;
